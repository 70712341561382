import React, { Component, Fragment } from "react";

const Maintenance = () => {
    return (
        <Fragment>
            {/* Start Page Error  */}
            <div className="error-page-inner bg_color--4">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <h1 className="theme-gradient">We are now on a new platform</h1>
                                <p style={{ color: 'white', fontSize: '20px' }}>You can find the new BGSNL platform with all current regions on <a href='https://bulgariansociety.nl/'>bulgariansociety.nl</a></p>
                                <img
                                    style={{ borderRadius: "50%", height: '100px', marginTop: '20px' }}
                                    src="/assets/images/logo/logo.jpg"
                                    alt="Logo"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Error  */}


        </Fragment>
    );
};

export default Maintenance;
